
.search-wrapper {
  justify-content: space-between;
  margin-bottom: 12px;
  .extra {
    display: flex;
    justify-content: flex-end;
  }
}

@primary-color: #00A4F5;@btn-border-radius-base: 4px;