
.set-wrapper {
  width: 500px;
  ::v-deep {
    .ant-checkbox-wrapper {
      width: 30%;
    }
  }
  margin-bottom: 20px;
}

@primary-color: #00A4F5;@btn-border-radius-base: 4px;