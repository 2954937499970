
.side-menu {
  position: fixed;
  padding: 20px 12px;
  box-sizing: border-box;
}

::v-deep {
  .ant-menu {
    .ant-menu-title-content {
      color: #575a7c;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
    }
  }
}

.side-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.side-menu .ant-menu-item:active {
  background: #e0effb;
}

@primary-color: #00A4F5;@btn-border-radius-base: 4px;