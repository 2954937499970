.side-menu {
  div,
  ul,
  li,
  .ant-menu-sub.ant-menu-inline {
    background-color: rgba(255, 255, 255, 0);
    color: #fff;
  }
  .ant-menu-light .ant-menu-submenu-title:hover {
    color: #fff;
  }
  .ant-menu-submenu > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #fff;
  }
  .ant-menu-submenu > .ant-menu-submenu-title:hover {
    background-color: #e0effb;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu-item:active {
    background: #e0effb;
  }
  .ant-menu-light .ant-menu-item:hover,
  .ant-menu-light .ant-menu-item-active {
    color: #fff;
  }
  //取消边距
  .ant-menu-inline .ant-menu-submenu-title,
  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-item {
    margin: 0;
    height: 36px;
  }
  .ant-menu-inline .ant-menu-item {
    margin-bottom: 0;
    height: 36px;
  }
  .ant-menu-item {
    // margin-top: 6px !important;
    &:first-child {
      margin-top: 0px !important;
    }
  }
  .ant-menu-inline {
    border: 0;
    .ant-menu-item-selected {
      &::after {
        display: none;
      }
      .menu-title {
        color: #1c95ff;
      }
    }
  }
  .ant-menu-submenu-arrow {
    color: rgba(52, 69, 99, 1) !important;
    right: 5px;
    width: 4px;
  }
  .ant-menu {
    & > li {
      margin-bottom: 6px !important;
    }
  }
}
