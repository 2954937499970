
.page-panel {
  padding: 16px 24px;
  background: #fff;
  .preTitle {
    font-size: 16px;
    font-weight: 400;
    color: #374561;
  }
  .page-title {
    display: flex;
    justify-content: space-between;
    .text {
      color: #374561;
      font-family: PingFang SC;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 28px */
    }
    .extra {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
      ::v-deep .anticon {
        cursor: pointer;
      }
    }
  }
  .table-wrapper {
    margin-top: 18px;
  }
}

@primary-color: #00A4F5;@btn-border-radius-base: 4px;