
.page-view {
  .search-wrapper {
    display: flex;
    align-items: flex-start;
    .search-form {
      flex: 1;
    }
  }
}

@primary-color: #00A4F5;@btn-border-radius-base: 4px;