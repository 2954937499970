
.page-header {
  display: flex;
  height: 70px;
  align-items: center;
  padding: 0 25px;
  justify-content: space-between;
  display: flex;
  background: #fff;
  position: relative;
  z-index: 100;
  box-shadow: 0px 0.5px 2px 0px rgba(0, 0, 0, 0.1);
  .left {
    flex: 0.8;
    align-items: center;
    .title {
      color: #1b1b25;
      font-family: PingFang SC;
      font-size: 18px;
      font-style: normal;
      width: 125px;
      padding-left: 17px;
      font-weight: 600;
      line-height: 140%; /* 25.2px */
    }

    .navs {
      margin-left: 66px;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 36px;
      .nav {
        display: flex;
        align-items: center;
        color: #374561;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        padding: 11px 8px;
        box-sizing: border-box;
        .label {
          margin-left: 10px;
        }
        &.active {
          color: #00a4f5;
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          border-radius: 6px;
          background: #f0f9fe;
        }
      }
    }
  }
  .header-right {
    color: #ffffff;
    font-size: 12px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    .nav {
      margin-left: 12px;
      display: flex;
    }
    .date {
      padding-right: 13px;
      border-right: 1px solid #fff;
    }

    .text {
      line-height: 24px;
    }
    .icon {
      margin-right: 4px;
    }
  }
}

.important-tooltip {
  animation: imp 3s;
  animation-iteration-count: infinite;
}

.header-right {
  display: flex;
  align-items: center;
  .icon-message {
    width: 24px;
    height: 24px;
  }
  .profile {
    margin-left: 30px;
    display: flex;
    align-items: center;
    .username {
      margin: 0 8px;
      color: #8791a3;
      text-align: center;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.icon-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

@primary-color: #00A4F5;@btn-border-radius-base: 4px;