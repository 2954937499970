
.page-title {
  height: 64px;
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  position: relative;
  padding: 0 24px;

  // margin-bottom: 16px;
  // &::before {
  //   position: absolute;
  //   display: block;
  //   content: "";
  //   width: 4px;
  //   height: 24px;
  //   background: #158fff;
  //   top: 12px;
  //   left: 0;
  // }
  .title {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #374561;
    line-height: 64px;
    text-align: left;
  }
}

@primary-color: #00A4F5;@btn-border-radius-base: 4px;