
.sub-side-bar {
  width: 48px;
  height: 100%;
  border: 1px solid var(--yy-gray-color);
  position: fixed;
  top: 70px;
  right: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    margin: 24px 0;
    cursor: pointer;
  }
}

@primary-color: #00A4F5;@btn-border-radius-base: 4px;