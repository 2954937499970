@import url("./layout.less");
@import url("./reset/antd.less");
@import url("./reset/menu.less");
@import url("./yy.less");

@font-face {
  font-family: "HYZhuZiChaoRanTi";
  src: url("../fonts/HYZhuZiChaoRanTiW.ttf");
}

@font-face {
  font-family: "DIN Alternate";
  src: url("../fonts/DIN\ Alternate\ Regular.otf");
}

.html {
  img {
    max-width: 100%;
  }
}

.flex {
  display: flex;
}

[role="button"] {
  cursor: pointer;
}

/* 全局滚动条样式 */
*::-webkit-scrollbar {
  width: 6px;
  height: 15px;
}
*::-webkit-scrollbar-thumb {
  background: #8791A3;
  border-radius: 8px;
  box-shadow: inset 0 0 5px #80bfff;
}
*::-webkit-scrollbar-track {
  background: rgba(201, 201, 201, 0);
  border-radius: 0;
  box-shadow: inset 0 0 5px rgba(201, 201, 201, 0);
}

.transparent.page-panel {
  padding: 0;
  background: transparent;
}

.empty {
  background-color: #000;
  background-image: url("../images/video-empty.png");
  background-position: center;
  background-repeat: no-repeat;
  * {
    display: none;
  }
}

//标签的样式
.ant-select-multiple .ant-select-selection-item {
  background-color: #e8f3ff;
  border: 0;
  line-height: 24px;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
  color: #165dff;
}

.login-view {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 0;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.column-center {
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-divider-vertical {
    margin: 0;
  }
}

@keyframes textclip {
  0%,
  100% {
    background-position: 200% 0%;
  }

  50% {
    background-position: 0% 200%;
  }
}

// #webpack-dev-server-client-overlay {
//   display: none;
// }

.table-clickable {
  cursor: pointer;
  color: var(--yy-primary-color);
}

.count-warning {
  color: var(--yy-error-color);
}

.edit-mode-disable {
  opacity: 0.5;
  pointer-events: none;
}

.row-selected {
  .ant-table-cell {
    background-color: #dcf3ff !important;
  }
}

.preview-row {
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
  .label {
    width: 120px;
  }
  .content {
    flex: 1;
  }
}

.usage-stat-item {
  box-sizing: border-box;
  padding-top: 16px;
  height: 100%;
  flex: 1;
  & > .title {
    font-weight: 900 !important;
    text-align: center;
  }
}

.icon-title-box {
  display: flex;
  align-items: center;
  svg {
    margin-right: 16px;
  }
}

.ant-menu-submenu-selected,
.ant-menu-item-selected.ant-menu-item-only-child {
  svg {
    color: var(--yy-primary-color);
  }
}

.drawer-footer-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}


@primary-color: #00A4F5;@btn-border-radius-base: 4px;