
.pagination-box {
  display: flex;
  margin-top: 24px;
  justify-content: flex-end;
}
::v-deep {
  .ant-table-cell {
    .ant-table-thead > tr > th {
      font-weight: 600 !important;
    }
  }
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  .status-drop {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 4px;
  }
}

@primary-color: #00A4F5;@btn-border-radius-base: 4px;